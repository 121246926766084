import "../style.css"
import "whatwg-fetch"
import { Application, Controller } from "@hotwired/stimulus"

class TooltipController extends Controller {
  static targets = [`tip`]
  declare readonly tipTarget: HTMLElement

  showTip() {
    this.tipTarget.hidden = false
  }

  hideTip() {
    this.tipTarget.hidden = true
  }

  toggleTip() {
    this.tipTarget.hidden = !this.tipTarget.hidden
  }
}

class FormController extends Controller {
  static targets = [`button`, `stepIndicator`, `stepContent`, `productOptions`, `form`]
  step: number = 0
  declare readonly stepIndicatorTargets: HTMLElement[]
  declare readonly stepContentTargets: HTMLElement[]
  declare readonly productOptionsTarget: HTMLElement
  declare readonly formTarget: HTMLFormElement

  connect() {
    this.refresh()
    this.element.addEventListener(`keydown`, this.keydown.bind(this))
  }

  disconnect() {
    this.element.removeEventListener(`keydown`, this.keydown.bind(this))
  }

  keydown(event: KeyboardEvent) {
    if (event.key === `Enter`) {
      event.preventDefault()
    }
  }

  refresh() {
    this.stepIndicatorTargets.forEach((el) => {
      if (parseInt(el.dataset.step as string) === this.step) {
        el.classList.remove(el.dataset.inactiveClass as string)
        el.classList.add(el.dataset.activeClass as string)
      } else {
        el.classList.add(el.dataset.inactiveClass as string)
        el.classList.remove(el.dataset.activeClass as string)
      }
    })
    this.stepContentTargets.forEach((el) => {
      if (parseInt(el.dataset.step as string) === this.step) {
        el.hidden = false
      } else {
        el.hidden = true
      }
    })
  }

  nextStep(e: MouseEvent) {
    const el = e.currentTarget as HTMLDivElement
    const stepContent = this.getStepContent(e)
    const inputs = Array.from(stepContent.querySelectorAll(`input, select`)) as Array<
      HTMLInputElement | HTMLSelectElement
    >
    if (inputs.every((input) => input.reportValidity())) {
      const step = parseInt(el.dataset.step as string)
      this.step = step
      this.refresh()
    }
  }

  async submit(e: MouseEvent) {
    e.preventDefault()
    const stepContent = this.getStepContent(e)
    const inputs = Array.from(stepContent.querySelectorAll(`input, textarea`))
    if (!inputs.every((input) => input.reportValidity())) {
      return
    }
    const formData = new FormData(this.element)
    const hash = Object.fromEntries(formData.entries())
    const resource = (e.currentTarget as HTMLButtonElement).dataset[`resource`]
    let body = {}
    body[resource] = hash
    const resp = await fetch(`/${resource}s`, {
      method: `POST`,
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
    })
    if (resp.redirected) {
      window.location.href = resp.url
    } else {
      this.element.querySelectorAll(`input`).forEach((input) => (input.value = ``))
      this.element.querySelectorAll(`textarea`).forEach((textarea) => (textarea.value = ``))
      this.element.querySelectorAll(`select`).forEach((select) => (select.value = ``))
      this.step = 2
      this.refresh()
    }
  }

  prevStep(e: MouseEvent) {
    const el = e.currentTarget as HTMLDivElement
    const step = parseInt(el.dataset.step as string)
    if (this.step !== 3 && step < this.step) {
      this.step = step
      this.refresh()
    }
  }

  private getStepContent(event: Event): Element {
    let el = event.target as HTMLElement
    while (true) {
      if (this.stepContentTargets.includes(el)) {
        break
      }
      el = el.parentElement as HTMLElement
    }
    return el
  }

  seedProductOptions(event: InputEvent) {
    const select = event.target as HTMLSelectElement
    this.productOptionsTarget.querySelectorAll("option:not([value=''])").forEach((option) => option.remove())
    if (select.value === ``) {
      this.productOptionsTarget.hidden = true
    } else {
      this.optionsForProductType(select.value).forEach((value) => {
        const option = document.createElement(`option`)
        option.value = value
        option.innerText = value
        this.productOptionsTarget.appendChild(option)
      })

      this.productOptionsTarget.hidden = false
    }
  }

  optionsForProductType(productType: string) {
    switch (productType) {
      case `Submersible Pool Cover Pump`:
        return [
          `Black & Decker 350 GPH Pool Cover Pump`,
          `Black & Decker 800 GPH Pool Cover Pump`,
          `Black & Decker 1500 GPH Automatic Pool Cover Pump`,
        ]
      case `Variable Speed Pool Pump`:
        return [
          `Black & Decker 1HP V.S. Above Ground Swimming Pool Pump`,
          `Black & Decker 1.5HP V.S. Inground Swimming Pool Pump`,
          `Black & Decker 2HP V.S. Inground Swimming Pool Pump`,
          `Black & Decker 3HP V.S. Inground Swimming Pool Pump`,
        ]
      case `Pool Brush`:
        return [`Black & Decker 360-Degree Bristles Pool Brush 18" `]
      case `Pool Alarm`:
        return [`Black & Decker Swimming Pool Alarm`]
      case `Heat Pump`:
        return [
          `BDXBT53 - 53,000 BTU`,
          `BDXBT80 - 80,000 BTU`,
          `BDXBT110 - 110,000 BTU`,
          `BDXBT137 - 137,000 BTU`,
        ]
      default:
        return []
    }
  }
}

class NavigationController extends Controller {
  static targets = [`registration`, `contact`, `registrationLink`, `contactLink`]
  declare readonly registrationTarget: HTMLElement
  declare readonly contactTarget: HTMLElement
  declare readonly registrationLinkTarget: HTMLElement
  declare readonly contactLinkTarget: HTMLElement
  activeClasses = [
    `bg-bd-orange`,
    `text-white`,
    `border-orange-600`,
    `hover:border-orange-900`,
    `hover:shadow-sm`,
  ]

  showRegistration() {
    this.registrationTarget.hidden = false
    this.contactTarget.hidden = true
    this.registrationLinkTarget.classList.remove(...this.activeClasses)
    this.contactLinkTarget.classList.add(...this.activeClasses)
    window.scroll(0, 0)
  }

  showContact() {
    this.registrationTarget.hidden = true
    this.contactTarget.hidden = false
    this.registrationLinkTarget.classList.add(...this.activeClasses)
    this.contactLinkTarget.classList.remove(...this.activeClasses)
    window.scroll(0, 0)
  }
}

// @ts-ignore
window.Stimulus = Application.start()
// @ts-ignore
Stimulus.register("form", FormController)
// @ts-ignore
Stimulus.register("tooltip", TooltipController)
// @ts-ignore
Stimulus.register("navigation", NavigationController)
